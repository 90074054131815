<template>
  <div class="invitation p20 bsbb">
    <van-nav-bar left-arrow @click-left="onClickLeft">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal;color: #fff;">邀請好友</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <div class="invitation-body">
      <div class="friend-main df fdc aic jcc">
        <div class="promotion p30 bsbb">
          <div class="bag-top">
            <div class="link1 mb20">分享RoAcDAPP鏈接</div>
            <div class="link2">{{ tuiUrl }}</div>
            <span class="link-btn" @click="handleClipboard(tuiUrl, $event)" style="background-color: #1a90c0;">複製</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import clipboard from "@/utils/clipboard";
import { getInvite, getCommission } from "@/utils/api.js";
export default {
  name: "invitation",
  data() {
    return {
      defaultImg: require("@/assets/images/invite3.png"),
      code: "",
      tuiUrl: "",
      downloadUrl: "",
      oneclass: "",
      twoclass: "",
      threeclass: "",
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getInvite().then((res) => {
        console.log(res);
        this.defaultImg = res.data.image;
        this.code = res.data.invitationCode;
        this.tuiUrl = res.data.tuiUrl;
        this.downloadUrl = res.data.downloadUrl;
      });
      getCommission().then((res) => {
        this.oneclass = Math.round(res.data.oneclass * 100);
        this.twoclass = Math.round(res.data.twoclass * 100);
        this.threeclass = Math.round(res.data.threeclass * 100);
      });
    },
    handleClipboard(text, event) {
      clipboard(text, event);
    },
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.van-dropdown-menu__bar {
  box-shadow: none !important;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

::v-deep .van-icon {
  font-size: 32px;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
  box-shadow: none !important;
}


.invitation {
  font-family: "pingfang";
  background: url(~@/assets/images/invite3.png) center no-repeat;
   background-size: cover;
  height: 100%;
  .invitation-body {
    .idNum {

      background: #fff;
      margin: 20px auto;
      border-radius: 10px;

      .id {
        display: flex;
        justify-content: space-around;

        >div {

          color: #000;
          border-radius: 10px;
          background: #e9f8ef;
        }
      }
    }
  }

  .friend-main {
    // width: 690px;
    // height: 600px;
    margin-top: 80%;

   // background: #1a90c0;
    color: #fff;
    border-radius: 20px;

    .link-btn2 {
      background: linear-gradient(180deg,
          rgba(55, 158, 0, 0.1) 0%,
          rgba(55, 159, 0, 0) 100%);
      border-radius: 26px;
      font-size: 13px;
      color: #fff;
      margin: 5px 0 10px 0;
      padding: 8px 25px;
    }

    .promotion {
      background: #fff;
      border-radius: 20px;
      width: 100%;

      .bag-top {
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        margin: 0 auto;
        padding: 16px;
        width: 90%;
        height: 200px;
        text-align: center;
        align-items: center;
        justify-content: center;
      }

      .link1 {
        color: #000;
        font-size: 0.6rem;
        font-weight: 700;
      }

      .link2 {
        word-break: break-all;
        word-wrap: break-word;
        font-size: 0.6rem;
        margin: 10px 0;
        color: #000;
      }

      .link-btn {
        width: 30%;
        height: 30%;
        text-align: center;
        background: red;
        border-radius: 26px;
        font-size: 0.5rem;
        font-weight: 700;
        margin-top: 30px;
      }
    }
  }
}
</style>
